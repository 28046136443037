const dataFaqs = [
	{
		id: 601,
		title: "How do I place an order?",
		text: "You may place an order through our website, add the service you opted for to the cart and pay at the digital check out.",
		show: "",
	},
	{
		id: 602,
		title: "Can I place a custom order?",
		text: "Custom orders are entertained. The delivery time and price depend on the project scale and complexity.",
		show: "",
	},
	{
		id: 603,
		title: "Can you make me a Logo?",
		text: "Yes, we provide graphic design services for logos, static graphics, posters, banners and more.",
		show: "",
	},
	{
		id: 604,
		title: "What is your creative process?",
		text: "Once you place your order, our customer service manager will set up a meeting where you may brief the project and inform us about your requirements. We will create a rough draft/ proposal for the project, Once it is approved from your end, we formally begin the project. Upon completion, we shall conduct another meeting where we present the end result. Once it is approved, the order is complete.",
		show: "",
	},
	{
		id: 605,
		title: "Who will be working on my project?",
		text: "We have professional copywriters, designers, art directors, videographers, visual effects artists and 3d artists, with years of experience and skills. Contact us via email if you would like to see our team members’ portfolios and resumes.",
		show: "",
	},
	{
		id: 606,
		title: "How long does a typical order take?",
		text: "Depends on the scale of the project, but a graphic would be delivered within 24 hours, a monthly calendar within 5-7 days, a complete video deliverable between 5-10 days, and a 3d project between 1-2 weeks (or more).",
		show: "",
	},
	{
		id: 607,
		title: "How many revisions can I get?",
		text: "You get three free revisions after which the first revision will cost RM50, the second RM75, the third RM100, with +25RM on each subsequent revisions.",
		show: "",
	},
	{
		id: 608,
		title: "What is the pricing structure and terms?",
		text: "You place an order by paying half the cost in advance which is non-refundable upon cancellation, and the rest upon completion.",
		show: "",
	},
	{
		id: 609,
		title: "What happens in the case of a cancellation of a project?",
		text: "If you have paid half the cost in advance, the advance is non refundable, however in an unlikely case of cancellation after the completion of the project, instead of during the project, where you have also paid the second half of the cost, that second half will be refunded.",
		show: "",
	},
	{
		id: 6010,
		title: "Can I get a discount?",
		text: "Yes! You can get pocket friendly discounts if you meet the eligibility requirements, check our discounts for more information.",
		show: "",
	},
	{
		id: 6011,
		title: "Do you provide contracts?",
		text: "We provide trial contracts, month-to-month renewable contracts, annual contracts, project based contracts, and custom contracts. For more details, contact us via email for information regarding the contracts.",
		show: "",
	},
	{
		id: 6012,
		title: "Can I avoid being in a contract if I cannot commit to a long term agreement?",
		text: "The answer is yes you can, under one condition, that the scale of the project is extremely small, with not more than one deliverable. If you do not want to be in a long term commitment contract, you can try our trial contract, which is relatively affordable and short term.",
		show: "",
	},
];

export default dataFaqs;
