import img1 from "../images/packages/social-media-posts-purple-still-life-of-a-vintage-computer-monitor-2023-11-27-05-00-28-utc.jpg";
import img2 from "../images/packages/video-production-trend-cyber-audio-poster-cassette-and-wave-in-neon-2023-11-27-05-11-08-utc.jpg";
import img3 from "../images/packages/3d-package-historical-antique-statue-of-david-s-head-and-mous-2023-11-27-04-52-31-utc.jpg";

const dataItem = [
	{
		id: 1111,
		img: img1,
		title: "Social Media Posts",
		details: [
			"10 Static Posts",
			"Copies",
			"Captions (Not included)",
			"Hashtags (Not included)",
			"Monthly Calendar of Posting Schedule",
		],
		price: "399",
		addPrice: "+50 (For captions and Hashtags)",
	},
	{
		id: 1112,
		img: img2,
		title: "Video",
		details: [
			"1 video deliverable",
			"Pre-Production",
			"Production",
			"Post-Production",
		],
		price: "299",
	},
	{
		id: 1113,
		img: img3,
		title: "3D",
		details: ["Modeling", "Animation"],
		price: "1999",
	},
];

export default dataItem;
