const menus = [
	{
		id: 1,
		name: "Home",
		links: "/",
	},
	{
		id: 2,
		name: "Services",
		links: "/services",
		// namesub: [
		// 	{
		// 		id: 1,
		// 		sub: "Copywriting",
		// 		links: "/services",

		// 		// links: "/services#copywriting",
		// 	},
		// 	{
		// 		id: 2,
		// 		sub: "Graphic Design",
		// 		links: "/services",
		// 		// links: "/services#graphic-design",
		// 	},
		// 	{
		// 		id: 3,
		// 		sub: "Video Production",
		// 		links: "/services",
		// 		// links: "/services#video-production",
		// 	},
		// 	{
		// 		id: 4,
		// 		sub: "3D Animation",
		// 		links: "/services",
		// 		// links: "/services#3d-animation",
		// 	},
		// ],
	},
	{
		id: 3,
		name: "Packages",
		links: "/packages",
		// namesub: [
		// 	{
		// 		id: 1,
		// 		sub: "Social Media Posts",
		// 		links: "/packages",
		// 	},
		// 	{
		// 		id: 2,
		// 		sub: "Video",
		// 		links: "/packages",
		// 	},
		// 	{
		// 		id: 3,
		// 		sub: "3D",
		// 		links: "/packages",
		// 	},
		// ],
	},
	{
		id: 4,
		name: "Deals",
		links: "/deals",
		// namesub: [
		// 	{
		// 		id: 1,
		// 		sub: "Davinci",
		// 		links: "/deals",
		// 	},
		// 	{
		// 		id: 2,
		// 		sub: "Muybridge",
		// 		links: "/deals",
		// 	},
		// 	{
		// 		id: 3,
		// 		sub: "Vincent",
		// 		links: "/deals",
		// 	},
		// 	{
		// 		id: 4,
		// 		sub: "Dali",
		// 		links: "/deals",
		// 	},
		// 	{
		// 		id: 5,
		// 		sub: "Salvator",
		// 		links: "/deals",
		// 	},
		// ],
	},
	{
		id: 5,
		name: "Discounts",
		links: "/discounts",
		// namesub: [
		// 	{
		// 		id: 1,
		// 		sub: "Referral Discount (20% Off)",
		// 		links: "/discounts",
		// 	},
		// 	{
		// 		id: 2,
		// 		sub: "First Handshake Discount (30% Off)",
		// 		links: "/discounts",
		// 	},
		// 	{
		// 		id: 3,
		// 		sub: "Favorite Client Discount (15% Off)",
		// 		links: "/discounts",
		// 	},
		// 	{
		// 		id: 4,
		// 		sub: "Testimonial Discount (5% Off)",
		// 		links: "/discounts",
		// 	},
		// ],
	},
	{
		id: 6,
		name: "Contact",
		links: "/contact",
	},
];

export default menus;
