import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";

import Speciality from "../components/speciality/Speciality";
import dataBox from "../assets/fake-data/data-box";

function Services(props) {
	return (
		<div className="wrapper">
			<PageTitle title="Services" />

			<Speciality data={dataBox} />

			<Footer />
		</div>
	);
}

export default Services;
