import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";

import dataItem from "../assets/fake-data/data-item";
import Project2 from "../components/project/Project2";

function Packages(props) {
	return (
		<div className="wrapper">
			<PageTitle title="Packages" />

			<Project2 data={dataItem} />

			<Footer />
		</div>
	);
}

export default Packages;
