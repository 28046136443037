import img1 from "../images/deals/davinci 2.webp";
import img2 from "../images/deals/Muybridge Horse.webp";
import img3 from "../images/deals/Van gogh 3.webp";
import img4 from "../images/deals/dali.webp";
import img5 from "../images/deals/Salvator Mundi 2.webp";

const dataDeals = [
	{
		id: 1001,
		img: img1,
		title: "Davinci",
		details: ["15 Static posts", "Captions", "Hashtags"],
		price: "499",
	},
	{
		id: 1002,
		img: img2,
		title: "Muybridge",
		details: ["3 Videos", "Captions", "Hashtags"],
		price: "699",
	},
	{
		id: 1003,
		img: img3,
		title: "Vincent",
		details: ["13 Static Posts", "2 Videos"],
		price: "949",
	},
	{
		id: 1004,
		img: img4,
		title: "Dali",
		details: ["2 3D models/animation"],
		price: "2999-3999",
	},
	{
		id: 1005,
		img: img5,
		title: "Salvator",
		addTitle: "Relax, we've got everything covered",
		details: [
			"15 static posts",
			"2 Videos",
			"2 3d animated videos",
			"Social Media Management",
			"DM and Story management",
		],
		price: "4999",
	},
];

export default dataDeals;
