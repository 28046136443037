import Contact from "./Contact";
import Deals from "./Deals";
import Home01 from "./Home01";
import Packages from "./Packages";
import Services from "./Services";
import Discounts from "./Discounts";
import Cart from "./Cart";

const routes = [
	{ path: "/", component: <Home01 /> },
	{ path: "/services", component: <Services /> },
	{ path: "/packages", component: <Packages /> },
	{ path: "/deals", component: <Deals /> },
	{ path: "/discounts", component: <Discounts /> },
	{ path: "/cart", component: <Cart /> },
	{ path: "/contact", component: <Contact /> },

	// { path: "/home-v2", component: <Home02 /> },
	// { path: "/home-v3", component: <Home03 /> },

	// { path: "/nfts", component: <Nfts /> },
	// { path: "/blog", component: <Blog /> },
	// { path: "/blog-details", component: <BlogDetails /> },
	// { path: "/visions-mission", component: <VisionsMission /> },
	// { path: "/help-center", component: <HelpCenter /> },
	// { path: "/participants-assets", component: <ParticipantAssets /> },
	// { path: "/advisors-backers", component: <AdvisorsBackers /> },
	// { path: "/partners", component: <Partners /> },
	// { path: "/about", component: <About /> },
	// { path: "/road-map", component: <RoadMap /> },
	// { path: "/team", component: <Team /> },
];

export default routes;
