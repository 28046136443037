import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo/Renaissance Final Black.png";

function Footer(props) {
	const [dataBlock] = useState({
		heading: "Get Newsletter",
		desc: "Get udpated with news, tips & tricks",
	});

	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 500) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<footer className="footer">
			<div className="shape"></div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						{/* <div className="footer__main">
							<div className="block-text center">
								<h3 className="heading">{dataBlock.heading}</h3>
								<p>{dataBlock.desc}</p>
							</div>
							<form action="#" className="form">
								<div className="form-group">
									<input
										type="email"
										className="form-control"
										placeholder="Your email"
									/>
								</div>
								<button className="action-btn">
									<span>Subscribe</span>
								</button>
							</form>
						</div> */}

						<div className="footer__bottom">
							<Link to="/" className="logo">
								<img
									style={{ width: "200px" }}
									src={logo}
									alt="Reanaissance Media Logo"
								/>
							</Link>

							<div className="center mb--30">
								<ul className="list">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/services">Services</Link>
									</li>
									<li>
										<Link to="/packages">Packages</Link>
									</li>
									<li>
										<Link to="/discounts">Discounts</Link>
									</li>
									<li>
										<Link to="/deals">Deals</Link>
									</li>
									<li>
										<Link to="/contact">Contact</Link>
									</li>
								</ul>
								<p>
									Copyright © {new Date().getFullYear()},
									Renaissance.
								</p>
							</div>
							<ul className="list-social">
								<li>
									<a
										target="_blank"
										href="https://www.facebook.com/profile.php?id=61561298112557"
									>
										<svg
											width="9"
											height="16"
											viewBox="0 0 9 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z"
												fill="white"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.instagram.com/the.renaissance.media?igsh=dTN4YXBoZnl0cXho&utm_source=qr"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-instagram"
											viewBox="0 0 16 16"
										>
											<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
										</svg>
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.linkedin.com/in/the-renaissance-media-1a3278316/"
									>
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z"
												fill="white"
											/>
											<path
												d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z"
												fill="white"
											/>
											<path
												d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z"
												fill="white"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.youtube.com/@therenaissanceproductions"
									>
										<svg
											width="18"
											height="12"
											viewBox="0 0 18 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17.2347 1.9639C17.1458 1.22216 16.4468 0.510897 15.7154 0.415609C11.2555 -0.138536 6.7457 -0.138536 2.28731 0.415609C1.55533 0.51069 0.856308 1.22216 0.76739 1.9639C0.452537 4.68236 0.452537 7.31818 0.76739 10.036C0.856308 10.7778 1.55533 11.4897 2.28731 11.5843C6.7457 12.1384 11.2557 12.1384 15.7154 11.5843C16.4468 11.4898 17.1458 10.7778 17.2347 10.036C17.5496 7.31842 17.5496 4.68236 17.2347 1.9639ZM7.58931 8.82375V3.17703L11.8243 6.00049L7.58931 8.82375Z"
												fill="white"
											/>
										</svg>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			{isVisible && (
				<Link to="#" onClick={scrollToTop} id="scroll-top">
					<span className="icon-arrow-top"></span>
				</Link>
			)}
		</footer>
	);
}

export default Footer;
