import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";

import dataItem from "../assets/fake-data/data-item";
import Project2 from "../components/project/Project2";
import Project3 from "../components/project/Project3";
import dataDeals from "../assets/fake-data/data-deals";

function Discounts() {
	const dataBox = [
		{
			id: 1,
			// img: img1,
			discount: "20%",
			title: "Referral Discount (20% off)",
			desc: "Found us resourceful? Refer to at least 2 people and get a 20% discount when they order!",
		},
		{
			id: 2,
			// img: img2,
			discount: "30%",
			title: "First Handshake Discount (30% off)",
			desc: "We do not take our client's trust for granted. Close a deal on our first meeting to avail this amazing offer!",
		},
		{
			id: 3,
			// img: img3,
			discount: "15%",
			title: "Favorite Client Discount (15% off)",
			desc: "Get a 15% discount after every 5 orders!",
		},
		{
			id: 4,
			// img: img4,
			discount: "5%",
			title: "Testimonial Discount (5% off)",
			desc: "For your feedback, send us a 30 to 60 seconds long video testimonial to get a 5% discount!",
		},
	];
	return (
		<div className="wrapper">
			<PageTitle title="Discounts" />

			{/* <Project3 data={dataDeals} /> */}

			<div className="container">
				<div className="row">
					{dataBox.map((idx) => (
						<div
							key={idx.id}
							className="discount-box col-xl-3 col-md-6"
						>
							<div className="speciality-box">
								<div
									className="icon"
									style={{
										background:
											"linear-gradient(-45deg, #ffd300, #ff6701 60%)",
									}}
								>
									{/* <img src={idx.img} alt="Renaissance Media" /> */}
									<h5
										className="title"
										style={{
											fontSize: "2em",
											textAlign: "center",
											marginTop: ".5em",
										}}
									>
										{idx.discount}
									</h5>
								</div>
								<h5 className="title">{idx.title}</h5>
								<p>{idx.desc}</p>
								<h3 className="number">0{idx.id}</h3>
							</div>
						</div>
					))}
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default Discounts;
