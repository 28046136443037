import React, { useState } from "react";

import img1 from "../../assets/images/about/dali-futuristic-statue-of-michelangelo-s-david-pop-head-2023-11-27-05-03-46-utc.jpg";
import img2 from "../../assets/images/about/davinci-creative-digital-artist-editing-video-at-work-amid-2024-02-27-03-20-13-utc.jpg";
import img3 from "../../assets/images/about/muybridge-film-industry-2023-11-27-05-23-20-utc.jpg";
import img4 from "../../assets/images/about/salvador-director-chair-and-clapper-board-and-megaphone-in-2023-11-27-05-11-40-utc.jpg";
import img5 from "../../assets/images/about/vincent-female-video-production-crew-on-set-2024-04-29-18-54-11-utc.jpg";
import Button from "../button/Button";

function About(props) {
	const [dataImg] = useState([
		{
			id: 1,
			img: img3,
			class: "img1",
		},
		{
			id: 2,
			img: img2,
			class: "img2",
		},
		{
			id: 3,
			img: img1,
			class: "img3",
		},
		{
			id: 4,
			img: img5,
			class: "img4",
		},
		{
			id: 5,
			img: img4,
			class: "img5",
		},
	]);

	const [dataBlock] = useState({
		subheading: "About us",
		heading: "Hight Quality Solutions For You",
		desc1: "Renaissance Media is a creative agency that specializes in social media marketing, digital advertising, video production, and 3D modeling/animation. ",
		desc2: "We offer professional, budget-friendly services targeted at small businesses and individuals. Our mission is to create, revive, and polish brands with unmatched creativity, attention to detail, and professionalism.",
	});
	return (
		<section className="about">
			<div className="shape"></div>
			<div className="container">
				<div className="row rev">
					<div className="col-xl-6 col-md-12">
						<div className="about__right">
							<div className="images">
								{dataImg.map((idx) => (
									<img
										key={idx.id}
										className={idx.class}
										src={idx.img}
										alt="Renaissance Media"
									/>
								))}
							</div>
						</div>
					</div>
					<div className="col-xl-6 col-md-12">
						<div className="block-text">
							<h6 className="sub-heading">
								<span>{dataBlock.subheading}</span>
							</h6>
							<h3 className="heading">{dataBlock.heading}</h3>
							<p className="mb-17">{dataBlock.desc1}</p>
							<p className="mb-26">{dataBlock.desc2}</p>
							<Button
								link="/contact"
								title="Need Help? Contact Us"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
