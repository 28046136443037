import React, { useState, useEffect, useContext } from "react";

import menus from "../../pages/menu";

import logo from "../../assets/images/logo/Renaissance Final Black.png";
import cart from "../../assets/images/icon/cart.png";

import "./styles.scss";
import { Link, NavLink } from "react-router-dom";
import { CartContext } from "../../context/cart";

const Header = () => {
	const { cartItems } = useContext(CartContext);

	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 300);
		});
		return () => {
			setScroll({});
		};
	}, []);

	const [menuActive, setMenuActive] = useState(null);

	const handleMenuActive = () => {
		setMenuActive(!menuActive);
	};

	const [activeIndex, setActiveIndex] = useState(null);
	const handleDropdown = (index) => {
		setActiveIndex(index);
	};

	return (
		<header
			id="header_main"
			className={`header ${scroll ? "is-fixed" : ""}`}
		>
			<div className="container big">
				<div className="row">
					<div className="col-12">
						<div className="header__body">
							<div className="header__logo">
								<a href="/">
									<img
										style={{ width: "160px" }}
										id="site-logo"
										src={logo}
										alt="Renaissance Media"
									/>
								</a>
							</div>

							<div className="header__right">
								<nav
									id="main-nav"
									className={`main-nav ${
										menuActive ? "active" : ""
									}`}
								>
									<ul id="menu-primary-menu" className="menu">
										{menus.map((data, idx) => (
											<li
												key={idx}
												onClick={() =>
													handleDropdown(idx)
												}
												className={`menu-item ${
													data.namesub
														? "menu-item-has-children"
														: ""
												} ${
													activeIndex === idx
														? "active"
														: ""
												}`}
											>
												<a href={data.links}>
													{data.name}
												</a>
												{data.namesub && (
													<ul className="sub-menu">
														{data.namesub.map(
															(submenu) => (
																<li
																	key={
																		submenu.id
																	}
																	className="menu-item"
																	style={{
																		fontWeight:
																			"400",
																	}}
																>
																	<a
																		href={
																			submenu.links
																		}
																	>
																		{
																			submenu.sub
																		}
																	</a>
																</li>
															)
														)}
													</ul>
												)}
											</li>
										))}
									</ul>
								</nav>
								<div
									className={`mobile-button ${
										menuActive ? "active" : ""
									}`}
									onClick={handleMenuActive}
								>
									<span></span>
								</div>
							</div>

							<div className="header__action">
								{/* <a href="/cart" className="search-btn">
									<img src={cart} alt="" />
									{cartItems.length > 0 && (
										<span className="cart-count">
											{cartItems.length}
										</span>
									)}
								</a> */}
								<a href="/contact" className="action-btn">
									<span>Contact Us</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		// <header className={`header ${scroll ? 'is-fixed' : ''}`}>
		//         <div className="tf-container">
		//             <div className="row">
		//                 <div className="col-md-12">
		//                     <div id="site-header-inner">
		//                         <div id="site-logo" className="clearfix">
		//                             <div id="site-logo-inner">
		//                                 <Link to="/" rel="home" className="main-logo">
		//                                     <img id="logo_header" className='logo-dark' src='{logodark}' alt="Binasea" />
		//                                     <img id="logo_header" className='logo-light' src='{logo}' alt="Binasea" />
		//                                 </Link>
		//                             </div>
		//                         </div>

		//                        <div className="header-center">
		//                         <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
		//                             <ul id="menu-primary-menu" className="menu">
		//                                 {
		//                                     menus.map((data,idx) => (
		//                                         <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

		//                                         >
		//                                             <Link to={data.links}>{data.name}</Link>
		//                                             {
		//                                                 data.namesub &&
		//                                                 <ul className="sub-menu">
		//                                                     {
		//                                                         data.namesub.map((submenu) => (
		//                                                             <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
		//                                                         ))
		//                                                     }
		//                                                 </ul>
		//                                             }

		//                                         </li>
		//                                     ))
		//                                 }
		//                             </ul>
		//                         </nav>
		//                        </div>

		//                         <div className="header-right">

		//                             <DarkMode />

		//                             <Link to="/contact" className="tf-button discord"><i className="icon-fl-vt"></i><span>DISCORD</span></Link>
		//                             <Link to="#" className="tf-button connect" data-toggle="modal" data-target="#popup_bid"> <i className="icon-fl-wallet"></i><span>CONNECT</span></Link>
		//                             <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
		//                         </div>

		//                     </div>
		//                 </div>
		//             </div>
		//         </div>

		//     </header>
	);
};

export default Header;
