import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
	const [cartItems, setCartItems] = useState(
		localStorage.getItem("cartItems")
			? JSON.parse(localStorage.getItem("cartItems"))
			: []
	);

	const addToCart = (item) => {
		const isItemInCart = cartItems.find(
			(cartItem) => cartItem.id === item.id
		); // check if the item is already in the cart

		if (!isItemInCart) {
			setCartItems([...cartItems, item]); // if the item is not in the cart, add the item to the cart
		} else {
			setCartItems(cartItems);
		}
	};

	const removeFromCart = (item) => {
		const isItemInCart = cartItems.find(
			(cartItem) => cartItem.id === item.id
		);

		if (isItemInCart) {
			setCartItems(
				cartItems.filter((cartItem) => cartItem.id !== item.id)
			); // if the quantity of the item is 1, remove the item from the cart
		} else {
			setCartItems(cartItems); // otherwise, return the cart items (do nothing)
		}
	};

	const clearCart = () => {
		setCartItems([]); // set the cart items to an empty array
	};

	const getCartTotal = () => {
		return cartItems.reduce(
			(total, item) => total + parseFloat(item.price),
			0
		); // calculate the total price of the items in the cart
	};

	useEffect(() => {
		localStorage.setItem("cartItems", JSON.stringify(cartItems));
	}, [cartItems]);

	useEffect(() => {
		const cartItems = localStorage.getItem("cartItems");
		if (cartItems) {
			setCartItems(JSON.parse(cartItems));
		}
	}, []);

	return (
		<CartContext.Provider
			value={{
				cartItems,
				addToCart,
				removeFromCart,
				clearCart,
				getCartTotal,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
