import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";

import { CartProvider } from "./context/cart";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
// 	"pk_test_51PL3VrRvksEqzM5ZvnIQbADqEfewx2FxufVL7LKe7yTfRBjhk4on5164OZE7z2t4wKJaDRBwa7ngwGorQFM15VU900QQ8NLQFa"
// );

// const options = {
// 	clientSecret:
// 		"sk_test_51PL3VrRvksEqzM5ZEl4VqegRi3alcWb24tGwKpho0Lcb4mOMVJI938PdrMrC7NLDc1yPGdvrPxpy0cGJAFdSTOOx00K0K2EpLY",
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.Fragment>
		<BrowserRouter>
			<ScrollToTop />
			<CartProvider>
				{/* <Elements stripe={stripePromise} options={options}> */}
					<App />
				{/* </Elements> */}
			</CartProvider>
		</BrowserRouter>
	</React.Fragment>
);
