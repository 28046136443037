import React, { useState } from "react";
import PropTypes from "prop-types";

import img from "../../assets/images/item/StatueGRAFIK2.png";

Portfolio.propTypes = {
	data: PropTypes.array,
};

function Portfolio(props) {
	const { data } = props;

	const [dataBlock] = useState({
		subheading: "Straightforward Process",
		heading: "Reinvent your brand in 4 steps",
	});
	return (
		<section className="portfolio">
			<div className="shape"></div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="block-text center">
							<h6 className="sub-heading">
								<span>{dataBlock.subheading}</span>
							</h6>
							<h3 className="heading pd">
								Reinvent your brand <br />
								in 4 steps
							</h3>
						</div>
					</div>
					<div className="col-xl-6 col-md-6">
						<div className="portfolio__left">
							{data.map((idx, i) => (
								<div key={idx.id} className="portfolio-box">
									<div
										className="step"
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											fontFamily: "Poppins, sans-serif",
											color: "#FF6701",
										}}
									>
										Step {i + 1}
									</div>
									<div className="icon">
										<img
											src={idx.img}
											alt="Renaissance Step"
											style={{
												width: "50px",

												height: "auto",
												// height: "50px",
											}}
										/>
									</div>
									<div className="content">
										<h5 className="title">{idx.title}</h5>
										<p>{idx.desc}</p>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="col-xl-6 col-md-6">
						<div className="portfolio__right">
							<div
								className="image"
								data-aos="fade-left"
								data-aos-duration="2000"
							>
								<img src={img} alt="Renaissance Media" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Portfolio;
