import React, { useState } from "react";

function Speciality(props) {
	const { data } = props;

	const [dataBlock] = useState({
		subheading: "Our Services",
		// heading: "Complete Solutions for your Brand",
		heading: "Everything you need",
		desc: "Renaissance Media is a creative powerhouse facilitating you with a range of services",
	});
	return (
		<section className="speciality" style={{ paddingTop: "150px" }}>
			<div className="shape right"></div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="block-text center">
							<h6 className="sub-heading">
								<span>{dataBlock.subheading}</span>
							</h6>
							<h3 className="heading pd">{dataBlock.heading}</h3>
							<p className="">{dataBlock.desc}</p>
						</div>
					</div>
					{data.map((idx) => (
						<div
							id={idx.sectionId}
							key={idx.id}
							className="col-xl-3 col-md-6"
						>
							<div className="speciality-box">
								<div
									className="icon"
									style={{
										background:
											"linear-gradient(to top, #ffd300, #ff6701)",
									}}
								>
									<img
										src={idx.img}
										alt="Services"
										style={{
											width: "50px",
											height: "50px",
										}}
									/>
								</div>
								<h5 className="title">{idx.title}</h5>
								<p>{idx.desc}</p>
								<h3 className="number">0{idx.id}</h3>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Speciality;
