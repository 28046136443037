import img1 from "../images/item/Aaliyan.jpg";
import img2 from "../images/item/Shofol.jpg";
import img3 from "../images/item/Afham.jpeg";

const dataTeam = [
	{
		id: 801,
		img: img1,
		name: "Aaliyan Rizvi",
		position: "Co-Founder & CEO",
		linkedin: "https://www.linkedin.com/in/aaliyan-rizvi-371b6224b/",
		instagram:
			"https://www.instagram.com/aali_riz?igsh=MTd1OG1rbDd1eWhlNw%3D%3D&utm_source=qr",
	},
	{
		id: 802,
		img: img2,
		name: "Saroar Bin Faruq Shofol",
		position: "Co-Founder & President",
		linkedin: "https://www.linkedin.com/in/sbfshofol/?originalSubdomain=bd",
		instagram: "https://www.instagram.com/sbf_shofol?igsh=MXRvb2Rsa215YzE2Zw==",
	},

	{
		id: 803,
		img: img3,
		name: "Afham Nanethan Shajahan",
		position: "Co-Founder & COO",
		linkedin: "https://www.linkedin.com/in/afham-shajahan-b03b98275?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
		instagram: "https://www.instagram.com/afhamshajahan?igsh=dnhnZTNha201djBx"
	},
];

export default dataTeam;
