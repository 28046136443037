import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";
import { useContext } from "react";
import { CartContext } from "../context/cart";

function Cart(props) {
	const { cartItems, removeFromCart, clearCart, getCartTotal } =
		useContext(CartContext);

	console.log(cartItems);
	return (
		<div className="wrapper">
			<PageTitle title="Cart" />

			{/* <Project3 data={dataDeals} /> */}
			<section className="nft">
				<div
					className="container"
					style={{
						border: "1px solid rgba(255, 255, 255, 0.2)",
						padding: "40px",
						borderRadius: "2em",
					}}
				>
					<div className="row">
						<div className="flex flex-col gap-4">
							{cartItems.map((item) => (
								<div
									className="flex justify-between items-center"
									key={item.id}
								>
									<div
										style={{
											display: "flex",
											gap: "1em",
											alignItems: "center",
											justifyContent: "space-between",
										}}
										className="cart-item-container"
									>
										<img
											src={item.img}
											alt={item.title}
											className="cart-item-container-image rounded-md h-24"
										/>
										<div>
											<h3
												style={{
													fontSize: "1.6em",
												}}
											>
												<p
													style={{
														fontFamily:
															"Poppins, sans-serif",
														fontSize: ".6em",
														color: "#eee",
														textTransform:
															"uppercase",
														letterSpacing: "0.2em",
													}}
												>
													Name
												</p>
												{item.title}
											</h3>
										</div>
										<h3
											className="text-gray-600"
											style={{
												fontSize: "1.6em",
											}}
										>
											<p
												style={{
													fontFamily:
														"Poppins, sans-serif",
													fontSize: ".6em",
													color: "#eee",
													textTransform: "uppercase",
													letterSpacing: "0.2em",
												}}
											>
												Price
											</p>
											{item.price} RM
										</h3>
										<p className="text-gray-600">
											{item.type.toUpperCase()}
										</p>
										<div
											className="header__action"
											style={{
												display: "flex",
												justifyContent: "end",
											}}
										>
											<button
												onClick={() => {
													removeFromCart(item);
												}}
												className="action-btn"
											>
												<span>Remove</span>
											</button>
										</div>
									</div>
								</div>
							))}
						</div>
						{cartItems.length > 0 ? (
							<div
								style={{
									paddingTop: "2em",
									paddingBottom: "1em",
								}}
							>
								<h3
									className="text-lg font-bold"
									style={{
										fontSize: "2em",
										textAlign: "end",
									}}
								>
									Total Price: {getCartTotal()} RM
								</h3>

								<div
									style={{
										marginTop: "1em",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<button
										onClick={() => {
											clearCart();
										}}
										className="clear-cart-btn"
									>
										<a>Clear cart</a>
									</button>
									<div className="header__action">
										<a
											href="/checkout"
											className="action-btn"
										>
											<span>Checkout</span>
										</a>
									</div>
								</div>
							</div>
						) : (
							<h3
								className="text-md font-bold text-center"
								style={{
									fontSize: "1.6em",
								}}
							>
								Your cart is empty
							</h3>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default Cart;
