import icon1 from "../images/icon/quote-2.png";
import avt1 from "../images/reviews/avatar.png";
import avt2 from "../images/reviews/female.png";
import avt3 from "../images/reviews/male.png";

const dataTestimonials2 = [
	{
		id: 701,
		icon: icon1,
		text: "“FANTASTIC creativity, worked quickly, and offered fantastic advice! Very pleased with their services and will utilize them for my next three 3D projects!!“",
		avt: avt1,
		name: "Von Elijah",
		// position: "Founder & CEO",
	},
	{
		id: 702,
		icon: icon1,
		text: "“Very friendly, super fast and the outcome really exceeded my expectations. I am more than satisfied! Thank you so much.“",
		avt: avt2,
		name: "Ronitsn",
		// position: "CEO & Founder at ThemeMu",
	},
	{
		id: 703,
		icon: icon1,
		text: "“Best quality video available for this price. Outstanding work.“",
		avt: avt3,
		name: "Samm Vanonna",
		// position: "Chief Product Officer",
	},
];

export default dataTestimonials2;
