// aali_riz
import img1 from "../images/portfolio/aali_riz/Coffee Shop.jpeg";
import img2 from "../images/portfolio/aali_riz/Coffee Shop2.jpeg";
import img3 from "../images/portfolio/aali_riz/Coffee Shop3.jpeg";
import img4 from "../images/portfolio/aali_riz/ACE 2021 Promo.jpeg";
import img5 from "../images/portfolio/aali_riz/ACE 2021 Promo2.jpeg";
import img6 from "../images/portfolio/aali_riz/COSMOS 2021.jpeg";

// afham
import img7 from "../images/portfolio/afhamshajahan/Chaos.jpeg";
import img8 from "../images/portfolio/afhamshajahan/Cozy Library.jpeg";
import img9 from "../images/portfolio/afhamshajahan/Middle of Nowhere.jpeg";
import img10 from "../images/portfolio/afhamshajahan/The Orb.jpeg";

// shofol
import img11 from "../images/portfolio/sbf_shofol/Cool Gel Ulitimate.jpeg";
import img12 from "../images/portfolio/sbf_shofol/Cool Gel Ultimate2.jpeg";
import img13 from "../images/portfolio/sbf_shofol/In the Mountains.jpeg";
import img14 from "../images/portfolio/sbf_shofol/In the Mountains2.jpeg";
import img15 from "../images/portfolio/sbf_shofol/In the Mountains3.jpeg";
import img16 from "../images/portfolio/sbf_shofol/Mickey Mouse.jpeg";
import img17 from "../images/portfolio/sbf_shofol/The Radiated Shoe.jpeg";
import img18 from "../images/portfolio/sbf_shofol/view.jpeg";
import img19 from "../images/portfolio/sbf_shofol/view2.jpeg";

import avt1 from "../images/item/Aaliyan.jpg";
import avt2 from "../images/item/Afham.jpg";
import avt3 from "../images/item/Shofol.jpg";

const dataCard = [
	{
		id: 101,
		img: img1,
		title: "Coffee Shop",
		price: "3d Model",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	{
		id: 102,
		img: img2,
		title: "Coffee Shop",
		price: "3d Model",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	{
		id: 103,
		img: img3,
		title: "Coffee Shop",
		price: "3d Model",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	{
		id: 104,
		img: img4,
		title: "ACE 2021 Promo",
		price: "Videography",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	{
		id: 105,
		img: img5,
		title: "ACE 2021 Promo",
		price: "Videography",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	{
		id: 106,
		img: img6,
		title: "COSMOS 2021",
		price: "Videography",
		avt: avt1,
		// name: "Aaliyan Rizvi",
		tag: "@aali_riz",
	},
	// afham
	{
		id: 201,
		img: img7,
		title: "Chaos",
		price: "Motion Graphics",
		avt: avt2,
		// name: "Afham Nanethan Shajahan",
		tag: "@afhamshajahan",
	},
	{
		id: 202,
		img: img8,
		title: "Cozy Library",
		price: "3d Model",
		avt: avt2,
		// name: "Afham Nanethan Shajahan",
		tag: "@afhamshajahan",
	},
	{
		id: 203,
		img: img9,
		title: "Middle of Nowhere",
		price: "3d Model",
		avt: avt2,
		// name: "Afham Nanethan Shajahan",
		tag: "@afhamshajahan",
	},
	{
		id: 204,
		img: img10,
		title: "The Orb",
		price: "3d Model",
		avt: avt2,
		// name: "Afham Nanethan Shajahan",
		tag: "@afhamshajahan",
	},
	// shofol
	{
		id: 301,
		img: img11,
		title: "Cool Gel Ultimate",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 302,
		img: img12,
		title: "Cool Gel Ultimate",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 303,
		img: img13,
		title: "In The Mountains",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 304,
		img: img14,
		title: "In The Mountains",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 305,
		img: img15,
		title: "In The Mountains",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 306,
		img: img16,
		title: "Mickey Mouse",
		price: "3d Model",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 307,
		img: img17,
		title: "The Radiated Shoe",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 308,
		img: img18,
		title: "The View",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
	{
		id: 309,
		img: img19,
		title: "The View",
		price: "3d Animation",
		avt: avt3,
		// name: "Saroar Bin Faruq Shofol",
		tag: "@sbf_shofol",
	},
];

export default dataCard;
