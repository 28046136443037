import img1 from "../images/icon/copywriting.png";
import img2 from "../images/icon/graphic designing.png";
import img3 from "../images/icon/video production.png";
import img4 from "../images/icon/3d.png";

const dataBox = [
	{
		id: 1,
		img: img1,
		sectionId: "copywriting",
		title: "COPYWRITING",
		desc: "Ad copies, Taglines, Captions, CTAs, & More!",
	},
	{
		id: 2,
		img: img2,
		sectionId: "graphic-design",
		title: "GRAPHIC DESIGN",
		desc: "Social Media, Static Posts, Logo Design, Banners, & More!",
	},
	{
		id: 3,
		img: img3,
		sectionId: "video-production",
		title: "VIDEO PRODUCTION",
		desc: "Promotional Videos, Reel, Ads/ Product, Videography &, More!",
	},
	{
		id: 4,
		img: img4,
		sectionId: "3d-animation",
		title: "3D ANIMATION",
		desc: "Modeling, Texturing, Rigging, Animation & More!",
	},
];

export default dataBox;
