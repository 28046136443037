import React, { useState } from "react";
import Button from "../button/Button";

import img from "../../assets/images/item/cta-image.png";

function Create(props) {
	const [dataBlock] = useState({
		heading: "Get in Touch",
		desc: "Our team will provide insights and recommendations to help you achieve your branding goals.",
	});
	return (
		<section className="create">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="create__main">
							<div className="content">
								<h4 className="heading">{dataBlock.heading}</h4>
								<p>{dataBlock.desc}</p>
								<Button title="Contact Now" link="/contact" />
							</div>
							<img src={img} alt="Renaissance Media Logo" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Create;
