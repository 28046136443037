import React from "react";
import dataBox from "../assets/fake-data/data-box";
import dataFaqs from "../assets/fake-data/data-faq";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import dataTeam from "../assets/fake-data/dataTeam";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Create from "../components/create/Create";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import Portfolio from "../components/portfolio/Portfolio";
import Speciality from "../components/speciality/Speciality";
import Team from "../components/team/Team";
import Banner2 from "../components/banner/Banner2";
import dataCard from "../assets/fake-data/data-card";
import Testimonials2 from "../components/testimonials/Testimonials2";
import dataTestimonials2 from "../assets/fake-data/data-testimonials2";
import Project3 from "../components/project/Project3";
import dataItem from "../assets/fake-data/data-item";
import Project2 from "../components/project/Project2";
import dataDeals from "../assets/fake-data/data-deals";

function Home01(props) {
	return (
		<div className="home-1 wrapper">
			<Banner />

			<Banner2 data={dataCard} />

			<Speciality data={dataBox} />

			<Project2 data={dataItem} />

			<Project3 data={dataDeals} />

			<Portfolio data={dataPortfolio} />
			
			<Team data={dataTeam} />

			<Testimonials2 data={dataTestimonials2} />

			<About />

			<Faqs data={dataFaqs} />

			<Create />

			<Footer />
		</div>
	);
}

export default Home01;
