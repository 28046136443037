import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart";
import { type } from "@testing-library/user-event/dist/type";
import img from "../../assets/images/background/line.png";

Project3.propTypes = {
	data: PropTypes.array,
};

function Project3(props) {
	const { data } = props;

	const { cartItems, addToCart } = useContext(CartContext);
	return (
		<section
			className="nft"
			style={{
				backgroundImage: `url(${img})`,
				backgroundSize: "cover",
				backgroundPosition: "bottom",
				backgroundRepeat: "no-repeat",
			}}
		>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="block-text center">
							<h6 className="sub-heading">
								<span>Deals</span>
							</h6>
							<h3 className="heading pd">Best Value</h3>
						</div>
					</div>

					{data.map((idx) => (
						<div key={idx.id} className="col-xl-4 col-md-4">
							<div className="nft-item">
								<div className="card-media">
									<Link to="#">
										<img
											src={idx.img}
											alt="Renaissance Media"
										/>
									</Link>
								</div>
								<div
									className="card-title"
									style={{ padding: "5px 15px" }}
								>
									<Link
										to="#"
										className="h5"
										style={{ fontSize: "1.6em" }}
									>
										{idx.title}
										<span
											style={{
												fontFamily:
													"Poppins, sans-serif",
												fontSize: ".6em",
												color: "#eee",
												textTransform: "none",
												display: "block",
											}}
										>
											{idx.addTitle ? idx.addTitle : null}
										</span>
									</Link>
								</div>
								<div
									className="meta-info"
									style={{ padding: "0px 15px" }}
								>
									<span>
										{" "}
										{idx.details
											? idx.details.map((point, i) => (
													<p
														style={{
															fontSize: "1.1em",
														}}
														key={i}
													>
														· {point}
													</p>
											  ))
											: null}
									</span>
								</div>
								<div
									className="card-bottom style-explode"
									style={{ padding: "15px 15px" }}
								>
									<div className="price">
										{/* <span className="icon-logo-01"></span> */}
										<div
											className="price-details"
											style={{ paddingLeft: "5px" }}
										>
											<span
												style={{
													color: "#ccc",
													fontSize: "1em",
												}}
											>
												{" "}
												Price
											</span>
											<h6 style={{ fontSize: "1.4em" }}>
												{idx.price} RM
											</h6>
										</div>
									</div>
									<div className="button-place-bid">
										{/* <button
											href=""
											onClick={() =>
												addToCart({
													...idx,
													type: "deal",
												})
											}
											data-toggle="modal"
											data-target="#popup_bid"
											className="sc-button"
											style={{
												border: "none",
												outline: "none",
											}}
										>
											<span>Add to Cart</span>
										</button> */}
										<a href="/contact">
											<button
												href="/contact"
												data-toggle="modal"
												data-target="#popup_bid"
												className="sc-button"
												style={{
													border: "none",
													outline: "none",
													background:
														"linear-gradient(-45deg, #FF00FF , #ff6701 60%)",
												}}
											>
												<span>Buy this Deal</span>
											</button>
										</a>
									</div>
								</div>
								{/* <span
									style={{
										color: "#ccc",
										fontSize: "1em",
										padding: "5px 15px",
									}}
								>
									{" "}
									{idx.addPrice}
								</span> */}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Project3;
