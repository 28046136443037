import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import img from "../../assets/images/background/line.png";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart";

Project2.propTypes = {
	data: PropTypes.array,
};

function Project2(props) {
	const { data } = props;
	const { cartItems, addToCart } = useContext(CartContext);

	return (
		<section className="nft" id="packages-section" >
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="block-text center">
							<h6 className="sub-heading">
								<span>Packages</span>
							</h6>
							<h3 className="heading pd">
								We're the spark,
								<br />
								You're the flame
							</h3>
						</div>
					</div>

					{data.map((idx) => (
						<div key={idx.id} className="col-xl-4 col-md-4">
							<div className="nft-item">
								<div className="card-media">
									<Link to="#">
										<img
											src={idx.img}
											style={{
												objectFit: "cover",
												width: "100%",
												height: "100%",
											}}
										/>
									</Link>
								</div>
								<div
									className="card-title"
									style={{ padding: "5px 15px" }}
								>
									<Link
										to="#"
										className="h5"
										style={{ fontSize: "1.6em" }}
									>
										{idx.title}
									</Link>
								</div>
								<div
									className="meta-info"
									style={{ padding: "0px 15px" }}
								>
									<span>
										{" "}
										{idx.details
											? idx.details.map((point, i) => (
													<p
														style={{
															fontSize: "1.1em",
														}}
														key={i}
													>
														· {point}
													</p>
											  ))
											: null}
									</span>
								</div>
								<div
									className="card-bottom style-explode"
									style={{ padding: "15px 15px" }}
								>
									<div className="price">
										{/* <span className="icon-logo-01"></span> */}
										<div
											className="price-details"
											style={{ paddingLeft: "5px" }}
										>
											<span
												style={{
													color: "#ccc",
													fontSize: "1em",
												}}
											>
												{" "}
												Starting from
											</span>
											<h6 style={{ fontSize: "1.4em" }}>
												{idx.price} RM
											</h6>
										</div>
									</div>
									<div className="button-place-bid">
										{/* <button
											// to=""
											data-toggle="modal"
											data-target="#popup_bid"
											className="sc-button"
											onClick={() =>
												addToCart({
													...idx,
													type: "package",
												})
											}
											style={{
												border: "none",
												outline: "none",
											}}
										>
											<span>Add to Cart</span>
										</button> */}
										<a href="/contact">
											<button
												href="/contact"
												data-toggle="modal"
												data-target="#popup_bid"
												className="sc-button"
												style={{
													border: "none",
													outline: "none",
													background:
														"linear-gradient(-45deg, #FF00FF , #ff6701 60%)",
												}}
											>
												<span>Buy this Package</span>
											</button>
										</a>
									</div>
								</div>
								<span
									style={{
										color: "#ccc",
										fontSize: "1em",
										padding: "5px 15px",
									}}
								>
									{" "}
									{idx.addPrice ? idx.addPrice : null}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Project2;

// import React , {useState} from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import Button from '../button/Button';

// Project2.propTypes = {
//     data: PropTypes.array
// };

// function Project2(props) {
//     const {data} = props;

//     const [dataBlock] = useState(
//         {
//             subheading: 'Our Speciality',
//             heading: 'OUR COLLECTION',

//         }
//     )
//     return (
//         <section className="project s2">
//                     <div className="shape right"></div>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-12">
//                                 <div className="block-text center">
//                                     <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
//                                     <h3 className="heading " >{dataBlock.heading}</h3>
//                                 </div>
//                             </div>

//                             {
//                                 data.map(idx => (
//                                     <div key={idx.id} className="col-xl-3 col-md-6">
//                                         <div className="project-box">
//                                             <div className="image">
//                                                 <Link to="/nfts">
//                                                     <img src={idx.img} alt="Renaissance Media" />
//                                                 </Link>
//                                             </div>
//                                             <div className="content">
//                                                 <Link to="/nfts" className="h5 title">{idx.title}</Link>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))
//                             }

//                             <div className="button mt-0">
//                                 <Button title='View All COLLECTION' link='/nfts' />
//                             </div>

//                         </div>
//                     </div>
//                 </section>
//     );
// }

// export default Project2;
