import img1 from "../images/icon/steps/1.png";
import img2 from "../images/icon/steps/2.png";
import img3 from "../images/icon/steps/3.png";
import img4 from "../images/icon/steps/4.png";
import img5 from "../images/icon/steps/5.png";
const dataPortfolio = [
	{
		id: 901,
		img: img1,
		title: "Service Selection",
		desc: "Select the service you wish to order",
	},
	{
		id: 902,
		img: img2,
		title: "Order Placement",
		desc: "Place your order through our website or contact our team members directly.",
	},
	{
		id: 903,
		img: img3,
		title: "Consultation Booking",
		desc: "Our customer service manager will you book a meeting with our designers where you can further elaborate the details of the project.",
	},
	{
		id: 904,
		img: img4,
		title: "Payment Process",
		desc: "Pay half the cost in advance, upon which your order will be placed. Once the project is complete and approved by your end, you shall then pay the remaining amount.",
	},
	{
		id: 905,
		img: img5,
		title: "Bonus",
		desc: "If you are satisfied, refer to your friends to get amazing discounts!",
	},
];

export default dataPortfolio;
