import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";

import dataItem from "../assets/fake-data/data-item";
import Project2 from "../components/project/Project2";
import Project3 from "../components/project/Project3";
import dataDeals from "../assets/fake-data/data-deals";

function Deals(props) {
	return (
		<div className="wrapper">
			<PageTitle title="Deals" />

			<Project3 data={dataDeals} />

			<Footer />
		</div>
	);
}

export default Deals;
